import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import LinkedInLogo from "../images/LI-Logo.png";

function ContactPage() {
  return (
    <Layout>
      <SEO
        title="Contact"
        keywords={[`versionprime`, `Pradeep Roark`, `Linkedin`]}
      />
      <section className="mx-auto">
        
        <div className="bg-gray-400 rounded-lg p-6 hover:shadow h-card">
                <div>
                  <h2 className="block text-lg bg-gray-400 p-name">Pradeep Roark</h2>
                  <div className="text underline p-job-title">Full Stack consultant</div>
                  <div><a className="u-email" href="mailto:pradeep.roark@versionprime.com">pradeep.roark@versionprime.com</a></div>
                  <div >
                      <a className="flex items-center px-2 -mx-2 " href="https://linkedin.com/in/pradeeproark/">    
                     <img src={LinkedInLogo} className="h-6" alt="linkedin profile"></img>
                          
                      </a>
                    </div>
                </div>
  </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
